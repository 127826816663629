var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"sorting":_vm.sorting,"columns":_vm.columns,"records":_vm.entities,"loading":_vm.loading},on:{"sort-changed":_vm.handlSortChanged},scopedSlots:_vm._u([{key:"Body-Rank",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.rank)+" ")]}},{key:"Body-Name",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(row.name))])]}},{key:"Body-TrustValue",fn:function(ref){
var row = ref.row;
return [(!row.latest_score)?_c('span',[_vm._v("n/a")]):_c('ColorizeValue',{attrs:{"value":_vm.formatNumber(row.latest_score),"colorize-text":false,"show-border":true}})]}},{key:"Body-NetChange",fn:function(ref){
var row = ref.row;
return [(!row.score_1day)?_c('span',[_vm._v("n/a")]):_c('ColorizeValue',{attrs:{"value":_vm.formatNumber(row.score_1day),"colorize-text":false,"show-border":true}})]}},{key:"Body-7Days",fn:function(ref){
var row = ref.row;
return [(!row.score_7days)?_c('span',[_vm._v("n/a")]):_c('ColorizeValue',{attrs:{"value":_vm.formatNumber(row.score_7days),"colorize-text":false,"show-border":true}})]}},{key:"Body-15Days",fn:function(ref){
var row = ref.row;
return [(!row.score_15days)?_c('span',[_vm._v("n/a")]):_c('ColorizeValue',{attrs:{"value":_vm.formatNumber(row.score_15days),"colorize-text":false,"show-border":true}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }