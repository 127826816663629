<template>
  <DataTable
    :sorting="sorting"
    :columns="columns"
    :records="entities"
    :loading="loading"
    @sort-changed="handlSortChanged"
  >
    <template #Body-Rank="{ row }">
      {{ row.rank }}
    </template>

    <template #Body-Name="{ row }">
      <span class="tw-font-bold">{{ row.name }}</span>
    </template>

    <template #Body-TrustValue="{ row }">
      <span v-if="!row.latest_score">n/a</span>
      <ColorizeValue
        v-else
        :value="formatNumber(row.latest_score)"
        :colorize-text="false"
        :show-border="true"
      />
    </template>

    <template #Body-NetChange="{ row }">
      <span v-if="!row.score_1day">n/a</span>
      <ColorizeValue
        v-else
        :value="formatNumber(row.score_1day)"
        :colorize-text="false"
        :show-border="true"
      />
    </template>

    <template #Body-7Days="{ row }">
      <span v-if="!row.score_7days">n/a</span>
      <ColorizeValue
        v-else
        :value="formatNumber(row.score_7days)"
        :colorize-text="false"
        :show-border="true"
      />
    </template>

    <template #Body-15Days="{ row }">
      <span v-if="!row.score_15days">n/a</span>
      <ColorizeValue
        v-else
        :value="formatNumber(row.score_15days)"
        :colorize-text="false"
        :show-border="true"
      />
    </template>
  </DataTable>
</template>

<script>
import { formatNumber } from '@/utils/number.js'
import ColorizeValue from '@/pages/trends/ColorizeValue.vue'
import DataTable from '@/pages/trends/DataTable.vue'

export default {
  components: {
    DataTable,
    ColorizeValue
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false
    },

    entities: {
      type: Array,
      required: true
    },

    sorting: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      columns: [
        {
          key: 'rank',
          slot: 'Rank',
          title: 'Rank',
          sortable: false,
          headerClass: 'tw-w-[10%]'
        },
        {
          key: 'name',
          slot: 'Name',
          title: 'Name',
          sortable: true,
          sortKey: 'name',
          headerClass: 'tw-w-[42%]'
        },
        {
          key: 'latest_score',
          slot: 'TrustValue',
          title: 'Trust Value',
          sortable: true,
          sortKey: 'latest',
          headerClass: 'tw-w-[12%]'
        },
        {
          key: 'score_1day',
          slot: 'NetChange',
          title: 'Net Change',
          sortable: true,
          sortKey: 'last-day',
          headerClass: 'tw-w-[12%]'
        },
        {
          key: 'score_7days',
          slot: '7Days',
          title: '7 Days',
          sortable: true,
          sortKey: 'last-7-days',
          headerClass: 'tw-w-[12%]'
        },
        {
          key: 'score_15days',
          slot: '15Days',
          title: '15 Days',
          sortable: true,
          sortKey: 'last-15-days',
          headerClass: 'tw-w-[12%]'
        }
      ]
    }
  },

  methods: {
    formatNumber: (number) => formatNumber(number),

    handlSortChanged (sorting) {
      this.$emit('sorting:update', {
        column: sorting.column,
        direction: sorting.direction
      })
    }
  }
}
</script>
